.dropdown_container {
  height: 100%;
  width: 150px;
  color: grey;
  cursor: pointer;
  display: block;
  position: relative;
  border: solid 1px #e5e5e5;
  padding: 10px 18px 11px 18px;
}
.dropdown_container span {
  width: 100%;
  color: grey;
  font-size: 14px;
}
.arabic .dropdown_container span {
  padding-right: 0;
  padding-left: 20px;
}
.dropdown_container:after {
  content: '';
  position: absolute;
  display: inline-block;
  right: 10px;
  background: url('../../images/small_grey_arrow.png') no-repeat;
  width: 10px;
  height: 8px;
  top: 44%;
  transition: all 0.2s;
}
.arabic .dropdown_container:after {
  right: auto;
  left: 5px;
}
.dropdown_container.filter_open:after {
  content: '';
  position: absolute;
  transform: rotate(-180deg) translateY(50%);
  top: 50%;
}
.showModal {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 9;
  border: 1px solid #e5e5e5;
  box-shadow: 0 12px 24px 0 rgba(43, 43, 43, 0.15);
  top: 45px;
  left: 0;
}
.arabic .showModal {
  direction: ltr;
}
.showModal:before {
  content: '';
  position: absolute;
  width: 0;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #fff;
  top: -7px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}
.showModal:after {
  content: '';
  position: absolute;
  width: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #e5e5e5;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.showModal .dropdown_content_container {
  padding: 5px 3px 5px 0;
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}
.showModal .dropdown_content_container li {
  padding: 10px 18px 11px 18px;
  display: block;
  border-bottom: 1px solid #f3f3f3;
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;
  color: #000;
}
.showModal .dropdown_content_container li:last-child {
  border-bottom: 0;
}

.showModal .dropdown_content_container li:hover {
  color: black;
  background-color: #efefef;
}
