.title-checkbox {
  margin-left: auto;
  padding-right: 80px;
}

.checkbox_wrap {
  display: inline-block;
}

.checkbox_wrap .custom_checkbox {
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0;
  position: absolute;
  z-index: 1;
}

.add-new-section {
  width: 17%;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.content h3 {
  max-width: 73%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sub-wrapper {
  border-top: 2px solid #e5e5e5;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
}

.wrapper-box .content + .data-wrapper {
  padding-top: 0;
}

.sub-wrapper .title-checkbox {
  margin-left: initial;
  padding-right: 0;
}

.section-ordering {
  position: absolute;
  right: 110px;
  cursor: pointer;
}

.section-ordering:hover:after {
  top: 5px;
  transform: rotate(180deg);
  transition: all 0.4s;
  cursor: pointer;
}

.section-ordering ul {
  display: none;
  list-style-type: none;
  position: absolute;
  top: 25px;
  width: 53px;
  height: auto;
  padding: 0;
  z-index: 1;
  right: 0px;
  background-color: #fff;
  border: 1px solid #414141;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
}

.section-ordering ul li {
  padding-left: 0;
  text-align: center;
}
