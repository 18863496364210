.flash-sale-dropdown {
  background-color: #ffd100;
  padding: 10px 20px;
  color: #8e7400;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  margin-bottom: 8px;
}

.flash-sale-toggle {
  top: 0px;
  margin-left: 10px;
  float: right;
}

.flash-sale-table {
  padding-left: 0px;
  padding-right: 0px;
}

.flash-sale-table .table {
  font-size: 14px;
  font-family: 'Futura PT Medium';
}

.dynamicBannerModule .wrapper-box .data-wrapper .st .tab-content .flash-sale-content {
  color: #333;
  font-weight: 400;
}

.flash-sale-process-btn {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 10px;
}

.flash-sale-csv-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
}

.flash-sale-action-btn {
  background: none;
  border: none;
}

.flash-sale-img {
  width: 25px;

  &:hover {

    transform: scale(5, 5);
  }
}

.flash-sale-upload-img {
  width: 43%;
  padding-bottom: 4px;
  margin-left: 30px;
  cursor: pointer;
}

.flash-sale-export-img {
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.flash-sale-input-grp-con {
  display: flex;
  justify-content: space-between;
}

.flash-sale-input {
  width: 45%;
}

.flash-sale-info-img {
  margin-left: 10px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flash-sale-custom-loader-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 2000;
}

.flash-sale-custom-loader-container {
  position: absolute;
  left: 45%;
  margin-left: -50px;
  width: 200px;
  height: 100px;
  z-index: 2000;
  margin-top: 6px;
}

.flash-sale-custom-loader {
  position: fixed;
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #4a90e2;
  /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin-left: 80px;
  margin-top: -20px;
}

.flash-sale-alert-notification {
  background-color: #f8d000;
  color: #222;
  font-size: 12px;
}

.flash-sale-export-txt {
  margin-top: 10px;
  background-color: #f8d000;
  color: #222;
  font-size: 12px;
}

.flash-sale-custom-progress-meter {
  box-sizing: content-box;
  height: 8px;
  position: relative;
  background: #e9e2e2;
  border-radius: 25px;
  padding: 1px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.flash-sale-custom-progress-meter>span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.flash-sale-custom-progress-meter>span:after,
.animate>span>span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate>span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}