.preview {
  .img-title {
    left: 13px;
    width: 145px;
  }

  .img-info {
    top: -23px;
    left: -4px;
  }
}

.container-height {
  margin-bottom: 0 !important;
}

.slider-image-list {
  .data-wrapper {
    .english-wrapper {
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      margin-bottom: 12px;
    }

    .arabic-wrapper {
      border-bottom: 1px solid #cccccc;
      margin-bottom: 12px;
    }

    .slider-control-input {
      padding-bottom: 16px;
    }
  }
  .order-control-btns {
    display: flex;
    align-items: center;
    position: absolute;
    right: 150px;

    svg {
      cursor: pointer;
    }
  }
}

.image-container {
  display: flex;
  justify-content: space-between;
}

.block-picker {
  z-index: 10;
}

.slider-checkboxes {
  display: flex;
}

.slider-options {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }

  .pickWrap {
    position: relative;
    margin: 0px 18px;
    .block-picker {
      position: absolute !important;
      right: -61px;
      top: 40px;
    }
  }

  .color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .slide-config {
    display: flex;
    align-items: center;
    width: 35%;

    .slider-config-select {
      width: 60%;
    }

    .drpdwn_wrapper {
      width: 66%;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      font-family: 'Futura PT Medium';
      margin-right: 5px;
    }
  }
}

.button-box-control {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-box-control-select {
    width: 30%;
    margin: 16px 7px 0px 0px;

    .Select-control {
      height: 40px;
    }
  }
}

.button-box {
  .data-wrapper {
    .wrapper-box {
      &:first-child {
        border-top: 1px solid #cccccc;
      }
    }
    .colors-wrapper {
      display: flex;
      justify-content: space-between;

      .swatch {
        padding: 5px;
        background: #fff;
        border-radius: 1px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        display: inline-block;
        cursor: pointer;
      }

      .pickWrap {
        display: flex;
        align-items: center;
        position: relative;

        span {
          margin-right: 10px;
        }

        .block-picker {
          position: absolute !important;
          right: -61px;
          top: 40px;
        }
      }

      .color {
        width: 36px;
        height: 14px;
        border-radius: 2px;
      }

      .popover {
        position: absolute;
        z-index: 2;
      }

      .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}
