.ckeditor-notes {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 0;
  border-top: 0;

  .sub-heading {
    padding: 5px 5px 6px 15px;
    font-size: 15px;
  }

  code {
    color: #337ab7;
  }
}


.wrapper-box {
  .ckeditor-notes {
    .heading-wrapper {
      min-height: 27px;
      padding-top: 5px;
      padding-bottom: 4px;
      
      h3 {
        font-size: 14px;
        font-family: 'Futura PT Book';
      }
    }
  }
}