@import './font.scss';
@import './variables.scss';
@import './mixins.scss';

@import './atom.scss';

html,
body {
  height: 100%;
}

.layout-boxed html,
.layout-boxed body {
  height: 100%;
}

body {
  //font-family: 'Futura PT Book', Helvetica, Arial, sans-serif;
  font-family: 'Futura PT Book';
  font-weight: 400;
  background-color: #ecf0f5;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
}

/* Layout */
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper:before,
.wrapper:after {
  content: ' ';
  display: table;
}

.wrapper:after {
  clear: both;
}

.layout-boxed .wrapper {
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}

/*
 * Content Wrapper - contains the main content
 * ```.right-side has been deprecated as of v2.0.0 in favor of .content-wrapper  ```
 */
.content-wrapper,
.right-side,
.main-footer {
  -webkit-transition: -webkit-transform 0.3s ease-in-out,
    margin 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 230px;
  z-index: 820;
}

.layout-top-nav .content-wrapper,
.layout-top-nav .right-side,
.layout-top-nav .main-footer {
  margin-left: 0;
}

@media (max-width: 767px) {
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .right-side,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .right-side,
  .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
    -o-transform: translate(230px, 0);
    transform: translate(230px, 0);
  }
}

.content-wrapper,
.right-side {
  min-height: 100%;
  z-index: 1;
}

.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
}

/* Fixed layout */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}

.fixed .main-header {
  top: 0;
  right: 0;
  left: 0;
}

.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 100px;
  }
}

.fixed.layout-boxed .wrapper {
  max-width: 100%;
}

/* General Links */
a {
  color: #3c8dbc;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #72afd2;
}

/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;
}

.page-header > small {
  color: #666;
  display: block;
  margin-top: 5px;
}

/*
 * Component: Main Header
 * ----------------------
 */

.main {
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  min-width: 100%;
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
}

.main-header .navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  background-color: #8c5898;
  min-height: 50px;
  border-radius: 0;
}

.layout-top-nav .main-header .navbar {
  margin-left: 0;
}

.main-header .logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
  background-color: #5f3868;
}

/*
 * Component: Sidebar
 * ------------------
 */
.main-sidebar,
.left-side {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  background-color: #15111f;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }

  .main-sidebar.active {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.sidebar {
  padding-bottom: 10px;
}

.sidebar-form input:focus {
  border-color: transparent;
}

.sidebar-form-inner {
  position: relative;
  width: 100%;
}

.user-panel {
  position: relative;
  width: 100%;
  background: #000;
  padding: 10px;
  overflow: hidden;
}

.user-panel:before,
.user-panel:after {
  content: ' ';
  display: table;
}

.user-panel:after {
  clear: both;
}

.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}

.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
  color: #fff;
}

.user-panel > .info > p {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 9px;
}

.user-panel > .info > a {
  text-decoration: none;
  padding-right: 5px;
  color: #fff;
  margin-top: 3px;
  font-size: 11px;
}

.user-panel > .info > a > .fa,
.user-panel > .info > a > .ion,
.user-panel > .info > a > .glyphicon {
  margin-right: 3px;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  text-decoration: none !important;
  display: block;
  color: #fff;
  border-left: 3px solid transparent;
}

.sidebar-menu > li:hover > a,
.sidebar-menu > li.active > a {
  background: #1e282c;
  border-left: 3px solid #3c8dbc;
}

.sidebar-menu > li > a > .fa {
  width: 20px;
}

.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
}

.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}

/* Page: Box */

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  margin-bottom: 20px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* Page: Login & Register */

.login-logo,
.register-logo {
  font-size: 22px;
}

.login-logo a,
.register-logo a {
  color: #444;
}

.login-page,
.register-page {
  background: #d2d6de;
}

.login-wrapper {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  display: flex;
}

.login-box,
.register-box {
  position: relative;
  width: 340px;
  margin: 0 auto;
  padding-top: 28%;
}

.login-box-body,
.register-box-body {
  background: #ffffffe3;
  border-top: 0;
  color: #666;
}

.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #777;
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.social-auth-links {
  margin: 10px 0;
}

.sidebar-form {
  padding: 20px;
}

#search-btn {
  height: 45px;
  border: 1px solid #ccc;
}

.left_thumb {
  width: 100%;
}

.left_thumb img {
  width: 100%;
}

.box-body {
  overflow: auto;
}

.table {
  min-width: 800px;
}

.paraText p:nth-child(even) {
  background: #f1f1f1;
  padding: 10px;
}

.main-header .navbar .sidebar-toggle {
  float: left;
  display: none;
  background-color: transparent;
  background-image: none;
  padding: 15px 15px;
  color: #fff;
  font-family: fontAwesome;
}

.main-header .navbar .sidebar-toggle:before {
  content: '\f0c9';
}

.main-header .navbar .sidebar-toggle:hover {
  background-color: #5f3868;
}

@media (max-width: 767px) {
  .main-header .navbar .sidebar-toggle {
    display: block;
    background-color: transparent;
  }
}

.main-header .logout {
  float: right;
  margin: 12px;
  margin-right: 20px;
  color: #fff;
  font-size: 18px;
}

@media (max-width: 767px) {
  .main-header .logout {
    margin: 13px 10px 0 0;
  }

  .main-header .logout span {
    display: none;
  }
}

.form-group label {
  text-align: right;
}

.form_sec .form-group {
  display: inline-block;
  width: 100%;
}

.alignCenter {
  text-align: center !important;
}

.alignRight {
  text-align: right !important;
}

.searchlist {
  list-style: none;
  padding: 0;
  background: #fafafa;
  border-radius: 0 0 5px 5px;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.searchlist li {
  padding: 10px;
  cursor: pointer;
}

.searchlist li:hover {
  background: #f1f1f1;
}

.searchFiled {
  height: 45px !important;
}

.loader-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000085;
  z-index: 2000;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  z-index: 2000;
}

.loader {
  position: fixed;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #4a90e2; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dashboard-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.parent-div h4 {
  color: #fff !important;
}

.parent-div .notification-dismiss {
  background: #fff !important;
  color: #1ab394 !important;
}

.parent-div .notification.notification-success.notification-visible:hover {
  background: #18a689 !important;
}

.parent-div
  .notification.notification-error.notification-visible
  .notification-dismiss {
  color: #b31e1e !important;
}

.parent-div
  .notification.notification-error.notification-visible
  .notification-dismiss:hover {
  background: rgba(197, 26, 26, 0.9) !important;
}

.marginRight {
  margin-right: 5px;
}

.marginLeft {
  margin-left: 5px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginLeft-20 {
  margin-left: 20px;
}

.marginRight-20 {
  margin-right: 20px;
}

.marginTop-30 {
  margin-top: 30px;
}

.m-t-10 {
  margin-top: -10px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-20 {
  padding-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.gplus-container {
  height: 70px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
  width: 70px;
  background-color: #5e4271d1;
  margin-bottom: 10px;
}

.img-google {
  margin-right: 15px;
  font-size: 16px !important;
}

.login-img-tint {
  position: absolute;
  background: #0000007a;
  width: 100%;
  height: 100%;
}

.uncheckedBox {
  width: 22px;
  height: 22px;
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  transition: 0.3s ease-in;
}

.uncheckedBox input[type='checkbox'] {
  opacity: 0;
  z-index: 10;
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0;
}

.uncheckedBox:hover {
  border: 2px solid #fdd130;
}

.checkedBox {
  width: 22px;
  height: 22px;
  background: #fdd130;
  border: 1px solid #fdd139;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  transition: 0.3s ease-in;
}

.checkedBox input[type='checkbox'] {
  opacity: 0;
  z-index: 10;
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0;
}

.checkedBox:after {
  content: '\2714';
  color: #fff;
  font-size: 16px;
  left: 4px;
  top: -1px;
  position: absolute;
}

.marginField {
  margin-bottom: 15px;
}

.position-relative {
  position: relative;
}

//.active {
//  color: #2ac12a;
//}

.in-active {
  color: #e01414b5;
}

.btn-cust-color {
  background: #fdd130;
  color: #ffffff !important;
  /* margin: 0 auto; */
  border-radius: 50%;
  font-size: 20px;
  margin: 4px 0 0 25px;
}

.btn-cust-color:hover {
  background: #f8cf2d !important;
}

.btn-cust-color:hover {
  background: #5f3868;
}

tr > th {
  text-align: center;
}

.verticalCenter {
  vertical-align: middle !important;
}

.order-detail-container {
  border: 1px solid #ececec;
  padding: 22px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 2px 2px 10px #e6e8e6;
}

.order-detail-container:hover {
  background-color: #dad5d51c;
}

.progress-bar-container:hover {
  background-color: #dad5d51c;
}

.order-sub-heading {
  color: #817c8c;
  font-weight: bold;
  background-color: #ffffff;
  margin-top: -35px;
  font-size: 17px;
  position: absolute;
  border-radius: 15%;
}

.progress-sub-heading {
  color: #817c8c;
  font-weight: bold;
  background-color: #ffffff;
  margin-top: -42px;
  left: 45%;
  font-size: 17px;
  position: absolute;
  border-radius: 15%;
}

@media screen and (max-width: 425px) {
  .progress-sub-heading {
    margin-top: -38px;
    left: 30px;
  }
}

.product-image-container {
  height: 130px;
  width: 140px;
  text-align: center;
  border: 1px solid #ffffff;
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: 0 0 5px #d1d4d1;
}

.product-image {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 430px) {
  .product-image-container {
    height: 60px;
    width: 70px;
    text-align: center;
  }
}

.content-size13 > p {
  font-size: 13px;
}

.content-size13 > p > strong {
  color: #888282;
}

@media screen and (min-width: 768px) {
  .min-height350 {
    min-height: 353px;
  }
}

.progress-bar-container {
  height: 235px;
  margin: 10px 15px 25px 15px;
  border: 1px solid #ececec;
  box-shadow: 2px 2px 10px #e6e8e6;
}

@media screen and (min-width: 426px) {
  .progress-bar-container {
    padding: 30px 0 0 10px;
  }
}

@media screen and (max-width: 425px) {
  .progress-bar-container {
    height: 295px;
    padding: 25px 0 0 0;
  }
}

.progress-bar-container > div {
  padding: 5px 0 6px 15px;
}

.progress-bar-container > div > div {
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  height: 30px;
  width: 30px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 50%;
}

.progress-element {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 425px) {
  .progress-bar-container > div > div {
    text-align: center;
    font-size: 12px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }
}

.progress-color {
  background-color: #ef9d06;
}

.error-progress-color {
  background-color: #ea18208f;
}

.no-progress-color {
  background-color: #d1d4d1;
}

.text-decoration {
  text-align: center;
  font-family: cursive;
}

.progress-bar-error {
  color: #d0cdcd;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-size: 17px;
}

@media screen and (max-width: 425px) {
  .progress-bar-arror {
    font-size: 13px;
    margin-top: 7px;
  }
}

.response-error {
  color: #e43b3b;
}

.delivered-color {
  background-color: #7fce82;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer-disabled-permission {
  cursor: not-allowed;
  opacity: 0.3;
}

.disable-permission {
  background-color: #808080 !important;
  background:#808080 !important;
  color: #fff;
  cursor: not-allowed !important;
}
.screenshot-wrapper {
  position: relative;
  min-height: 100px;
  background: #f9f9f9;
}

.screenshot-icon-wrapper {
  position: absolute;
  display: none;
  z-index: 1;
  cursor: pointer;
}

.screenshot-label {
  text-align: center;
  margin-bottom: 20px;
  font-size: 12px;
  color: #f129b9;
  overflow: hidden;
  text-overflow: ellipsis;
}

.screenshot-wrapper:hover .screenshot-icon-wrapper {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.screenshot-icon-wrapper:after {
  content: '\F00E';
  font-family: FontAwesome;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
  font-size: 40px;
  background-color: black;
}

.screenshot-img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.screenshot-icon-wrapper:hover + .screenshot-img {
  opacity: 0.4;
}

.screenshot-img:hover {
  opacity: 0.4;
}

.fontSize13 {
  font-size: 13px;
}

.pagination-font {
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 320px) {
  .pagination-font {
    font-size: 7px;
  }
}

.noBorder {
  border: none !important;
}

.checkbox_wrap {
  position: relative;
}

.arabic .checkbox_wrap {
  text-align: right;
}

.checkbox_wrap .custom_checkbox {
  width: 20px;
  height: 20px;
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.arabic .checkbox_wrap .custom_checkbox {
  right: 0;
}

.checkbox_wrap .custom_checkbox:checked + .checkbox_value:before {
  background-image: -moz-linear-gradient(90deg, #f89e00 0%, #f8d000 100%);
  background-image: -webkit-linear-gradient(90deg, #f89e00 0%, #f8d000 100%);
  background-image: -ms-linear-gradient(90deg, #f89e00 0%, #f8d000 100%);
  border: none;
}

.checkbox_wrap .custom_checkbox:checked + .checkbox_value:after {
  background: url('../../images/black_check.png') no-repeat;
  background-size: contain;
}

.checkbox_wrap .custom_checkbox:checked:disabled + .checkbox_value:before {
  background: #eaeaea;
}

.checkbox_wrap .custom_checkbox + .checkbox_value {
  display: inline-block;
  vertical-align: middle;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  margin-right: 15px;
  transition: color 0.3s;
}

.arabic .checkbox_wrap .custom_checkbox + .checkbox_value {
  padding-left: 0;
  padding-right: 30px;
}

.checkbox_wrap .custom_checkbox + .checkbox_value:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  border: 2px solid #f8d000;
}

.arabic .checkbox_wrap .custom_checkbox + .checkbox_value:before {
  left: auto;
  right: 0;
}

.checkbox_wrap .custom_checkbox + .checkbox_value:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 8px;
  display: inline-block;
  top: 8px;
  left: 5px;
}

.arabic .checkbox_wrap .custom_checkbox + .checkbox_value:after {
  left: auto !important;
  right: 5px;
}

.react-datepicker__navigation--previous {
  border-right-color: #236ba5 !important;
}

.react-datepicker__navigation--next {
  border-left-color: #236ba5 !important;
}

.bidi-override {
  unicode-bidi: bidi-override;
}

.display-none {
  display: none !important;
}

.dnd-heading {
  background: #3f3f3f !important;
  h3 {
    color: #fff !important;
  }
}

.data-wrapper {
  background-color: $backgroundColor;
}

.cursor-default {
  cursor: default !important;
}
