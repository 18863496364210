.custom-sortable-tree {
  height: 500px;
}
.dnd-btn {
  padding: 0;
  margin: 0 9px;
  border: 0;
  background: transparent;
  outline: none;
  vertical-align: middle;
}
.dnd-edit-btn {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url('../../images/pencil-edit-button.png');
  background-repeat: no-repeat;
}
.dnd-delete-btn {
  display: block;
  width: 13px;
  height: 16px;
  background-image: url('../../images/delete-button.png');
  background-repeat: no-repeat;
}
.rst__moveHandle {
  background: url('../../images/move-arrows.png') no-repeat center;
  box-shadow: none;
  border: 1px solid #959595;
  border-right-color: transparent;
}
.rst__rowContents {
  box-shadow: none;
  border: 1px solid #959595;
}
.rst__rowTitle {
  font-family: 'Futura PT';
  font-size: 16px;
  /* font-weight: 500; */
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #525252;
}
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: #959595;
}

.rst__collapseButton,
.rst__expandButton {
  box-shadow: 0 0 0 1px #959595;
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
  box-shadow: 0 0 0 1px #959595, 0 0 1px 3px #83bef9;
}

.rst__lineChildren::after {
  background-color: #959595;
}
