.close-icon {
  background: url('../../images/icon-cross.png');
  background-repeat: no-repeat;
  padding: 7px;
  top: 11px;
  position: absolute;
  background-size: 11px 11px;
  cursor: pointer;
}

.close-btn {
  text-align: end;
}

.confirm-btn {
  width: 100%;
  height: 49px;
  background-color: #000000;
  border: none;
}

.action-btn {
  width: 40%;
  height: 32px;
  background-color: #000000;
  border: none;
}

.n-action-btn {
  width: 40%;
  height: 32px;
  background-color: white;
  border: 2px solid black;
}

.n-btn-text {
  width: 91px;
  height: 14px;
  font-family: 'Futura PT';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.btn-text {
  width: 91px;
  height: 14px;
  font-family: 'Futura PT';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.column-height {
  height: 49px !important;
}

.modal-heading {
  font-family: 'Futura PT';
  font-size: 33px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.modal-text {
  font-family: 'Futura PT';
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: block;
  margin-top: 12px;
  margin-bottom: 8px;
}

.btn-disabled {
  background-color: gray;
}

.input-disable {
  background-color: #eee !important;
}

.custom-input {
  float: left;
  height: 100%;
}

.custom-file-selection {
  width: 100% !important;
  height: 100% !important;
}

.file-label {
  line-height: 38px !important;
  height: 100% !important;
}

.custom-file-label::after {
  height: 100% !important;
  line-height: 38px !important;
}

.input-grp-height {
  height: 15%;
}

.file-upload {
  padding: 13px 0 13px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  border: none;
}

.file-close-icon {
  background: url('../../images/icon-cross.png');
  background-repeat: no-repeat;
  padding: 7px;
  top: 50%;
  right: 10px;
  position: absolute;
  background-size: 11px 11px;
  cursor: pointer;
  transform: translateY(-50%);
}

.file-upload-container {
  position: relative;
  border: 1px solid lightgray;
  margin-top: 20px;
}

.text-align {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgray;
  line-height: 0.1em;
}

.input-file-disable {
  pointer-events: none;
}

.or-content {
  background: #fff;
  padding: 0 10px;
}

.user-type {
  height: 49px !important;
  border-radius: initial !important;
}

.r-15 {
  right: 15px;
}
