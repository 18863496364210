.add-menu-btn {
  margin-top: 0;
  margin-right: 0;
  border: none;
  background-color: #e8c43e;
  color: black;

  &:hover {
    background-color: #ffd100;
    transition: all 0.3s;
  }

  &-wrapper {
    text-align: center;

    &::after {
      display: none;
    }

    &:hover {
      background-color: #000 !important;
    }
  }
}