/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #f5f5f5;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  border-right: 1px solid #ccc;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  position: relative;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.img-container {
  height: 115px;
  margin: 12px;
  margin-bottom: 20px;
}

.center-align {
  text-align: center;
}

.img-container > img {
  height: 100%;
}

.image-btn-content {
  margin: 20px 10px;
  position: relative;
  width: 250px;
}

.selected-img {
  border: 2px solid #fdd130;
}
.tabcontent h3 {
  font-size: 20px;
  font-family: 'Futura PT Medium', Arial, SansSerif;
  margin-left: 10px;
}

.imageHeading h3 {
  text-align: center;
  margin-left: 0px;
}

.col-section-wrapper img {
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}
.imageHeading .img-info {
  left: 180px;
}

.positionToolTip {
  position: relative;
}

.positionToolTip .img-info {
  top: 11px;
  left: 72px;
}

.row.imageRow {
  background-color: #f7f7f7de;
  margin: 0 -12px;
  padding-top: 35px;
  border-bottom: 1px solid #ccc;
}

.row.imageRow .img-preview {
  justify-content: center;
}

.row.imageRow0 {
  border-top: 1px solid #ccc;
}

.imageHeading .button-wrapper {
  height: 147px;
  position: relative;
}
.imageHeading .button-wrapper button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  margin: 0 auto;
}

.column-header-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.delete-megamenu-btn {
  color: #ffffff;
  background: #000000;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

label {
  margin-right: 5px;
}

.mega-menu-col {
  padding: 6px 12px;
}

.footer-padding {
  padding: 6px 12px;
}

.img-info.category-img-info {
  left: 117px;
}

.super-price-container > .react-toggle {
  top: 5px;
  left: 10px;
}

.cust-info {
  position: relative;
  margin-left: 25px;
  top: 6px;
}

.object-fit-scale-down {
  object-fit: scale-down;
}

.copy-column-wrapper {
  position: relative;
}

.column-actions {
  display: flex;
  align-items: center;
  align-content: center;

  .dropdown-content {
    display: block;
    top: 64px;

    .dropdown-item {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
