.nav-arrow-down {
    padding: 0 6px;

    &:active {
        opacity: 0.7;
    }

    &.opened {
        transform: rotate(180deg);
    }
}

.nav-btn {
    background: none;
    border: none;

    &:active {
        opacity: 0.7;
    }

    &[disabled] {
        opacity: 0.3;
    }
}

.nav-img {
    height: 12px;
    width: 12px;
}

.add-nav-menu-btn {
    margin: 5px 5px 5px 5px;

}