.panel-container {
  min-height: 500px;
  display: flex;
}

.right-container {
  flex: 4;
}

.disable-btn {
  cursor: not-allowed !important;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.content-container-wrapper {
  flex: 1;
  margin-bottom: 152px;
}

.overflow-hidden {
  overflow: hidden;
}

.content-container {
  height: 100%;
  padding: 20px 30px 0 30px;
}

.side-nav {
  padding: 15px 0 15px 0;
  height: 56px;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  display: block;
  border-top: 1px solid #ffffff33;
  cursor: pointer;
}

.side-nav:hover {
  background: #4a90e2;
}

// .selected {
//   background: #4a90e2;
// }

.custom-textarea {
  margin: 0;
  height: 300px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  width: 100%;
}

.required {
  color: #f21a1a;
}

.required:after {
  content: '*';
}

.content-colour {
  color: #007aff !important;
}

.add-btn {
  color: #000000 !important;
  font-weight: bold;
}

.float-right {
  float: right;
  cursor: pointer;
}

.m-t-3 {
  margin-top: 3px;
}

.schedulerTitle {
  color: #9b9b9b;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 7px;
}

.dateTitle {
  height: 16px;
  width: 34px;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

/*--------react toggle-------------*/

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  height: 20px;
  width: 37px;
  padding: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1.5px solid #e5e5ea;
  border-radius: 15.5px;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ffffff;
}

.react-toggle--checked .react-toggle-track {
  background-color: #4cd964;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4cd964;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #fafafa;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05),
    0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 19px;
  border-color: #4cd964;
}

.custom-toggle-checked > div > div {
  border: 0;
}

.custom-toggle {
  position: absolute;
  right: 100px;
  top: 33px;
}

@media (min-width: 1200px) {
  body .container {
    width: 1220px;
    margin: 0 auto;
    padding: 0;
  }
}

.pad-left-0 {
  padding-left: 0;
}

.wrapper-box .info img {
  width: 15px;
  vertical-align: top;
  margin-top: 1px;
}

.wrapper-box .info .tooltiptext {
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50.1%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 25px;
  min-width: 150px;
  width: auto;
  height: auto;
  max-width: 220px;
  background-color: #8b8b8b;
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  -webkit-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  font-size: 12px !important;
  line-height: 14px;
}

.wrapper-box .info .tooltiptext:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 1;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #8b8b8b transparent transparent;
}

.wrapper-box .info:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.form-group.disable-input {
  position: relative;
}

.form-group.disable-input .form-control {
  opacity: 0.7;
  background-color: #eaeaea;
  cursor: not-allowed;
}

.wrapper-box .data-wrapper {
  padding: 10px 20px;
}

.wrapper-box .data-wrapper .picker-wrapper {
  width: 100%;
}

.wrapper-box .data-wrapper .english-wrapper,
.wrapper-box .data-wrapper .arabic-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.wrapper-box .data-wrapper textarea {
  border-color: #d5d5d5;
  width: 100%;
}

.wrapper-box .data-wrapper .arabic-wrapper {
  padding-left: 15px;
}

.wrapper-box .data-wrapper h4 {
  font-size: 16px;
  color: #ba7101;
  font-family: 'Futura PT Medium', Arial, SansSerif;
  font-weight: 500;
}

.wrapper-box .data-wrapper .form-control:focus {
  border: 1px solid #ffd100;
  box-shadow: none;
}

.wrapper-box .data-wrapper .form-control {
  border-radius: 0;
  &:not(textarea) {
    height: 38px;
  }
}

.wrapper-box .data-wrapper .form-group label {
  font-size: 16px;
  color: #797979;
  font-family: 'Futura PT Book', Arial, SansSerif;
  font-weight: 300;
  font-style: normal;
}

.wrapper-box
  .data-wrapper
  .form-group
  .custom-calender
  .form-control::-webkit-inner-spin-button,
.wrapper-box
  .data-wrapper
  .form-group
  .custom-calender
  .form-control::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  z-index: 1;
  width: 26px;
  height: 22px;
}

.wrapper-box .data-wrapper .form-group .custom-calender {
  position: relative;
}

.wrapper-box .data-wrapper .form-group .custom-calender .date-calender {
  content: '';
  position: absolute;
  background: url('../../images/icon-calendar.svg') no-repeat;
  width: 26px;
  height: 22px;
  top: 38px;
  right: 14px;
  z-index: 0;
  cursor: pointer;
}

.preview {
  width: 130px;
  height: 130px;
  background-color: #eee;
  position: relative;
}

.img-preview {
  display: flex;
  position: relative;
}

.uploaded-image img {
  object-fit: scale-down;
  width: 130px;
  height: 130px;
}

.left-spacing {
  padding-left: 15px;
}

.camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 21px;
  margin-top: 45px;
  margin-left: 40px;
  position: absolute;
  background-size: 45px 34px;
  cursor: pointer;
}

.marginBottom {
  margin-bottom: 50px !important;
}

.img-title {
  position: absolute;
  top: -26px;
  font-family: 'Futura PT Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #797979;
}

.hover-img-title {
  position: absolute;
  top: -26px;
  font-family: 'Futura PT Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #797979;
}

.icon-hover:hover .icon-close {
  background: url('../../images/dustbin.png');
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  padding: 14px;
  top: 5px;
  background-size: 28px 23px;
  margin-left: 101px;
}

.hover-icon:hover .icon-play {
  background: url('../../images/play.png');
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  padding: 14px;
  top: 6px;
  background-size: 28px 23px;
  margin-left: 0;
}

.icon-hover:hover .hover-camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 17px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-size: 37px 30px;
  cursor: pointer;
}

.hover-icon:hover .icon-close {
  background: url('../../images/dustbin.png');
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  padding: 14px;
  top: 5px;
  background-size: 28px 23px;
  margin-left: 101px;
}

.hover-icon:hover .img-overlay {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hover-icon:hover .hover-camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 17px;
  top: 50px;
  left: 46px;
  position: absolute;
  background-size: 37px 30px;
  cursor: pointer;
}

.opacity {
  opacity: 0.5;
}

.icon-hover:hover .img-overlay {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
}

.img-dimension {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-icon {
  width: 35%;
}

.img-info {
  position: absolute;
  top: -23px;
  left: 62px;
}

.hover-img-info {
  position: absolute;
  top: -23px;
  left: 254px;
}
.responsive-img-info {
  position: absolute;
  top: -23px;
  left: 129px;
}
.icon-img-info {
  position: absolute;
  top: -23px;
  left: 88px;
}

.custom-toggle.home-page-display-toggle {
  top: 18px;
  left: 335px;
}

.custom-toggle.home-page-display-toggle .react-toggle {
  top: 0;
}

.scheduler-actions {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.banner-scheduler-wrapper {
  border: 1px solid #e5e5e5;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #eee3;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }
}

.scheduler-actions .scheduler-add-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #ffd100;
  width: 180px;
  color: #3f3f3f;
  border: none;
  height: 35px;
  font-size: 12px;
  border-radius: 20px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 72vw;
  background: #e3e3e3;
  border-radius: 5px;

  .footer-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    .custom-btn {
      margin: 0 15px 0 0;
      border-radius: 75px;
    }

    .apply-for-wrapper {
      width: 50%;
      justify-content: initial;
    }

    .drpdwn_wrapper {
      width: 100%;
      border-radius: 6px;

      .apply-for-dropdown {
        .dropdown {
          .dropdown-content {
            top: -383px;
            left: 0;
          }
        }
      }
    }
  }
}

.preview-datepickers {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-group {
    width: 100%;
  }
}

.footer-wrapper #dropbtnApplyFor {
  bottom: 45px;
  position: fixed;
  top: auto !important;
  left: auto !important;
  width: 33%;
}
