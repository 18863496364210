.store-container {
  height: 160px;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
}

.stores {
  position: relative;
  height: 120px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.store-selected {
  box-shadow: 0 0 7px 2px rgba(193, 193, 190, 0.5);
}

.store-unselected {
  background: #efefef;
}

.inner-container {
  text-align: center;
  padding-top: 15px;
  font-weight: bold;
  cursor: pointer;
}

.inner-container-unselected {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: #d3d3d340;
  cursor: pointer;
}

.inner-container > div {
  padding: 10px 15px 0 15px;
}
