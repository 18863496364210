.user-management-container {
  width: 100%;
  padding-left: 10px;
}
.permissions-container {
  width: 100%;
  padding-left: 10px;
  padding-bottom:100px;
}

.user-management-table {
  padding-left: 42px;
  padding-right: 30px;
}

.total-user-text {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.round-btn {
  width: 183px;
  height: 40px;
  background-color: #000000;
  border: none;
  &[disabled] {
    opacity: 0.3;
    background-color: #787878;

  }
}

.round-btn {
  &:not([disabled]){
    &:hover{
      .btn-text{
        color: #ffd100;

      }
    }
  }
}

.btn-text {
  font-family: inherit;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.search-icon-btn {
  position: absolute;
  top: 0;
  height: 38px;
  right: 15px;
  padding-left: 10px;
  background-color: #000;
  border: 1px solid darkgray;
}

.search-icon {
  background: url('../../images/icon-search.png');
  background-repeat: no-repeat;
  background-size: 15px 16px;
  vertical-align: middle;
  padding-top: 1px;
  padding-left: 12px;
  padding-right: 8px;
}

.plus-icon {
  background: url('../../images/icon-plus.png');
  background-repeat: no-repeat;
  padding: 16px;
  top: 7px;
  left: 15px;
  position: absolute;
  background-size: 25px 25px;
}

.user-management-table .table {
  font-size: 12px;
  font-family: 'Futura PT Medium';
}

.user-management-table .table > thead > tr > th {
  padding: 9px 8px 9px 25px !important;
  text-align: start;
  background-color: #e9e9e9;
  color: #797979;
}

.user-management-table .table > tbody > tr > th {
  padding: 8px 22px 8px 25px !important;
}

.user-management-table .table > tbody > tr > td {
  padding: 9px 8px 9px 25px !important;
}

.user-management-table .table > tbody > tr > td:nth-child(3) {
  width: 20%;
}

.user-management-table .table > tbody > tr > td:nth-child(4) {
  width: 20%;
}

.user-management-table .table > tbody > tr > td:nth-child(5) {
  width: 20%;
}

.user-icon {
  background: url('../../images/icon-user.png');
  background-repeat: no-repeat;
  padding: 16px;
  margin-top: -5px;
  margin-left: -18px;
  position: absolute;
  background-size: 32px 32px;
}

.delete-icon {
  background: url('../../images/icon-delete.png');
  background-repeat: no-repeat;
  padding: 16px;
  position: absolute;
  background-size: 16px 20px;
}

.text-border {
  border-bottom: 1px solid black;
}

.view-number {
  font-family: 'Futura PT';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #787878;
}

.btn-disabled {
  background-color: gray;
}

.pagination {
  float: right;
  margin-top: 0 !important;
  border-radius: 2px !important;
  background-color: white;
}

.pagination > li > a {
  outline: none;
}

.pagination > .active > a:focus {
  outline: none;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  border-radius: 2px;
  color: #222 !important;
  cursor: default;
  width: 31px;
  height: 30px;
  background-color: #f8d000 !important;
  border-color: transparent !important;
}

.pagination > li > a,
.pagination > li > span {
  padding: 4px 12px !important;
  color: #222 !important;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 31px;
  height: 30px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.text-align-middle {
  text-align: center;
}

.permissions-footer {
  width: 100% !important;
  .footer-wrapper {
    justify-content: flex-end;
  }
}
