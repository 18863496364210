.side-panel {
  position: sticky !important;
  top: 10px;
  padding: 0 25px !important;
}

.side-bar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-bar p {
  margin: 0;
}

.side-bar .apply-for-dropdown {
  background-color: #ffd100;
  padding: 10px 20px;
  color: #8e7400;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
}

.side-bar .apply-for-dropdown p {
  position: relative;
}

.side-bar .nav-child > span {
  cursor: pointer;
  padding: 10px 21px;
  background-color: #000;
  font-size: 16px;
  color: #fff;
  display: block;
  position: relative;
  height: 38px;
  box-sizing: border-box;
}

.side-bar .nav-child > span:last-child {
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
}

.side-bar .nav-child > span:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 30px;
}

.side-bar .nav-child > span.minus-icon-nav:after {
  background: url('../../images/icon-minus.svg') no-repeat;
  width: 12px;
  height: 3px;
  top: 20px;
  background-size: contain;
}

.side-bar .catalog-sub-nav.minus-icon-nav:after {
  background: url('../../images/icon-minus.svg') no-repeat;
  width: 12px;
  height: 3px;
  top: 15px;
  background-size: contain;
}

.side-bar .static-sub-nav.minus-icon-nav:after {
  background: url('../../images/icon-minus.svg') no-repeat;
  width: 12px;
  height: 3px;
  top: 15px;
  background-size: contain;
}

.side-bar .nav-child > span.plus-icon-nav:after {
  background: url('../../images/icon-plus.svg') no-repeat;
  top: 15px;
  width: 12px;
  height: 12px;
  background-size: contain;
}

.side-bar .catalog-sub-nav.plus-icon-nav:after {
  background: url('../../images/icon-plus.svg') no-repeat;
  top: 10px;
  width: 12px;
  height: 12px;
  background-size: contain;
}

.side-bar .static-sub-nav.plus-icon-nav:after {
  background: url('../../images/icon-plus.svg') no-repeat;
  top: 10px;
  width: 12px;
  height: 12px;
  background-size: contain;
}

.catalog-nav > li:nth-child(n + 2):after {
  display: none;
}

.static-nav > li:nth-child(n + 2):after {
  display: none;
}

.side-bar span:hover {
  color: #fff;
  text-decoration: none;
}

.side-bar .nav-child ul {
  background-color: #000;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -ms-border-radius: 0 0 5px 5px;
}

.side-bar .nav-child ul li {
  font-size: 14px;
  color: #ffffff;
  padding: 5px 20px;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  min-height: 30px;
}

.side-bar .nav-child ul li:last-child {
  padding: 5px 20px 10px;
}

.side-bar .nav-child ul li:after {
  content: '';
  position: absolute;
  right: 20px;
  background: url('../../images/arrow-dd-normal.svg') no-repeat;
  width: 5px;
  height: 10px;
  top: 10px;
}

.side-bar .nav-child ul li.active,
.side-bar .nav-child ul li:hover {
  color: #ffd100;
  background-color: #3f3f3f;
}

.apply-for-dropdown .dropdown {
  width: 100%;
  margin-top: 3px;
  transition: all 0.4s;
  cursor: pointer;
}

.apply-for-dropdown .dropdown:after {
  content: '';
  position: absolute;
  background: url('../../images/arrow-dd.svg') no-repeat;
  top: 8px;
  right: 0;
  width: 10px;
  height: 10px;
  transition: all 0.4s;
}

.apply-for-dropdown .dropdown:hover:after {
  top: 5px;
  transform: rotate(180deg);
  transition: all 0.4s;
}

.apply-for-dropdown .dropdown .dropdown-content {
  top: 20px;
  left: -14px;
  width: 100%;
}

.side-bar {
  .apply-for-dropdown {
    .dropdown-content {
      top: 20px;
    }
  }
}

.apply-for-dropdown .dropdown .dropbtn {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  white-space: break-spaces;
}

.apply-for-dropdown .dropdown .dropdown-content span {
  color: #ffffff;
  background-color: #1f1f1f;
  border: 1px solid #3f3f3f;
}

.apply-for-dropdown .dropdown .dropdown-content span:hover {
  color: #ffd100;
  background-color: #3f3f3f;
}

.side-bar .nav-child .category_listing.active,
.side-bar .nav-child .category_listing:hover,
.side-bar .nav-child .static_listing.active,
.side-bar .nav-child .static_listing:hover {
  background: none;
}

.side-bar .nav-child .category_listing:hover:after,
.side-bar .nav-child .static_listing:hover:after {
  top: 15px;
  transform: rotate(270deg);
  transition: all 0.4s;
}

.side-bar .nav-child .category_listing li:hover,
.side-bar .nav-child .static_listing li:hover {
  background: none;
}

#catalogAddPageDisplay,
#staticAddPageDisplay,
#sectionsAddPageDisplay,
#configurationsAddPageDisplay {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

#catalogAddPageDisplay:after,
#staticAddPageDisplay:after,
#sectionsAddPageDisplay:after,
#configurationsAddPageDisplay:after {
  content: none;
}

.side-bar .nav-child .category_listing:after,
.side-bar .nav-child .static_listing:after {
  top: 15px;
  transform: rotate(90deg);
  transition: all 0.4s;
}

.side-bar .nav-child ul .sub_listing {
  background: #3f3f3f;
  margin: 0;
}

.side-bar .nav-child .category_listing .sub_listing li,
.side-bar .nav-child .static_listing .sub_listing li {
  padding: 9px 23px 9px 13px;
  border-bottom: 1px dashed #747474;
}

.side-bar .nav-child .category_listing li:after,
.side-bar .nav-child .static_listing li:after {
  content: none;
}

.side-bar .nav-child .category_listing .sub_listing li:last-child,
.side-bar .nav-child .static_listing .sub_listing li:last-child {
  border-bottom: none;
}

.side-bar .nav-child .category_listing .sub_listing li:hover,
.side-bar .nav-child .static_listing .sub_listing li:hover {
  color: #ffd100;
}

.side-bar .nav-child .category_listing .sub_listing li:hover:after,
.side-bar .nav-child .static_listing .sub_listing li:hover:after {
  transform: rotate(0deg);
  background: url('../../images/arrow-dd-normal-hover.svg') no-repeat;
}

.side-bar .nav-child .category_listing .sub_listing li:after,
.side-bar .nav-child .static_listing .sub_listing li:after {
  content: '';
  top: 40%;
  position: absolute;
  right: 20px;
  background: url('../../images/arrow-dd-normal.svg') no-repeat;
  width: 5px;
  height: 10px;
  transform: rotate(0deg);
}

.sections-sub-nav {
  background-color: #3f3f3f !important;
  font-size: 16px !important;
  color: #8b8b8b !important;
  padding: 10px 20px 10px !important;
}
.sections-nav {
  padding-top: 20px;
}

.sections-sub-nav .module-title img {
  margin-right: 10px;
}
.sections-nav .sub_listing,
.sections-nav .sub_listing li.active,
.sections-nav .sub_listing li:hover {
  background-color: #333 !important;
}
.sections-nav .sub_listing li.active,
.sections-nav .sub_listing li:hover {
  color: #ffd100 !important;
}
#sections .sidebar-btn,
#configurations .sidebar-btn,
.sections-sub-nav .sidebar-btn,
.sections-nav .sub_listing .sidebar-btn {
  background: none;
  border: none;
}
.sections-sub-nav .sidebar-btn img {
  width: 12px;
}
.sections-nav .folder-arrow-down {
  padding: 0 6px;
  filter: invert(100%);
  height: 6px;
  width: 23px;
  &:active {
    opacity: 0.7;
  }
  &.opened {
    transform: rotate(180deg);
  }
}

.sections-nav .folder-arrow-down-opened .folder-arrow-down {
  transform: rotate(180deg);
}

.sections-sub-nav,
.sections-nav .sub_listing li {
  display: flex;
  justify-content: space-between;
}
.sections-nav .sub_listing li {
  padding: 5px 20px 5px 50px !important;
}
#configurations .page-icons-container,
#sections .page-icons-container,
.sections-sub-nav .page-icons-container {
  align-items: center;
}
.sections-nav .sub_listing li:after {
  display: none;
}
#configurations,
#url-page,
#flash-sale-page,
#sections {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#configurations:after,
#sections:after,
.sections-nav li:after,
.sections-sub-nav :after {
  display: none;
}
.catalog-banner-heading h3,
.slider-banner-heading h3 {
  color: #fff !important;
}

.catalog-banner-heading,
.slider-banner-heading {
  background: #3f3f3f !important;

  .validate-btn {
    background: #ffffff !important;
    color: black;
    position: absolute;
    right: 150px;
    padding: 0;
    margin: 0;
    height: 30px;
    border-radius: 20px;
    width: 100px;
    min-width: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .valid {
    background: green !important;
    color: white;
  }
}

ul.catalog-nav,
ul.static-nav {
  padding: 10px 0;
  padding-bottom: 0;
}

.side-bar .nav-child ul.list-padding {
  padding-bottom: 15px;
}

.side-bar .nav-child ul li.add-menu-btn-wrapper {
  padding-top: 10px;
  padding-bottom: 5px;
}

.sidebar-btn {
  background: none;
  border: none;
  &:active {
    opacity: 0.7;
  }
  &[disabled] {
    opacity: 0.3;
  }
}

.url-sidebar-toggle {
  top: 4px;
}
