.search-wrapper {
  position: relative;
}

.search-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #cccccc;
  padding: 12px;
  position: absolute;
  width: calc(100% - 15px);
  background-color: #fff;
  top: 75px;
  left: 0;
  z-index: 2;
}

.search-container p {
  margin-bottom: 0;
  cursor: pointer;
  max-width: calc(100% - 15px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list-heading {
  top: 35px;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  font-family: 'Futura PT Book', Arial, SansSerif;
}

.size-chart .img-preview .img-info {
  left: 90px;
}

.new-category-label {
  font-family: 'Futura PT Book', Arial, SansSerif;
  font-size: 15px;
  margin-top: 30px;
}

.new-category-label > span {
  font-weight: bold;
}

.no-data {
  font-family: 'Futura PT Medium', Arial, SansSerif;
  font-size: 15px;
}

.side-bar .apply-for-dropdown.size-chart-label {
  color: #000;
  padding: 18px 0 19px;
  text-align: center;
  font-family: 'Futura PT Medium';
  font-size: 20px;
}
