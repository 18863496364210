.custom-checkbox {
  width: 22px;
  height: 22px;
  background: #fff;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  transition: 0.2s ease-in;

  input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    z-index: 10;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
  }

  &.checked {
    background: #fdd130;
    border: 1px solid #fdd139;

    &::after {
      content: '\2714';
      color: #fff;
      font-size: 16px;
      left: 4px;
      top: -1px;
      position: absolute;
    }

    &:hover {
      background-color: #fff;

      &::after {
        color: #fdd130;
      }
    }
  }
  
  &:not(.checked):hover {
    border: 2px solid #fdd130;
  }
}

