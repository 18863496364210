.pages-containers-wrapper {
  margin-bottom: 80px;
}

.folders-container {
  background-color: black;
  margin: 6px 0;

  .folders-container-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 22px;

    .container-arrow-down {
      padding: 0 6px;
      filter: invert(100%);

      &.opened {
        transform: rotate(180deg);
      }
    }
  }

  .container-name {
    font-size: 16px;
    color: white;
  }

  .sidebar-btn {
    background: none;
    border: none;

    &:active {
      opacity: 0.7;
    }

    &[disabled] {
      opacity: 0.3;
    }
  }

  .folder {
    border-bottom: #636363 1px solid;
    flex-wrap: wrap;
    color: #FFFFFF;

    .folder-header {
      display: flex;
      justify-content: space-between;
      padding: 12px 22px;
      background-color: #3f3f3f;
      transition: all 1s ease-in-out;

      &.added {
        box-shadow: inset 0 0 5px 5px #b99801;
      }

      .folder-arrow-down {
        padding: 0 6px;
        filter: invert(100%);

        &:active {
          opacity: 0.7;
        }

        &.opened {
          transform: rotate(180deg);
        }
      }

      &.homepage-folder {
        height: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    .folder-title {
      display: flex;
      max-width: 50%;

      input {
        border: none;
      }
    }

    span.folder-name {
      font-size: 16px;
      margin-left: 10px;
    }

    .folder-icons-container {
      min-width: 97px;

      .sidebar-btn {
        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .pages-list {
      width: 100%;
      background-color: #333;

      .home-icon {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 5px;
        transform: translate(100%, 65%);
      }

      .page {
        display: flex;
        position: relative;
        padding: 12px 12px 12px 12px;
        // justify-content: space-between;

        .drag-ping {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(50%, 50%);
          min-width: 20px;
          min-height: 20px;

          .pin-icon {
            cursor: pointer;
          }
        }

        .page-title {
          position: relative;
          cursor: pointer;
          transition: all 0.5s ease-in-out;

          &:hover,
          &.active-page {
            color: #ffd100;
          }

          &.added-page {
            text-shadow: 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #bce600,
              0 0 25px #bce600;
          }

          .page-index {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-150%);

            &:hover {
              color: #ffd100;
            }
          }
        }

        .page-icons-container {
          position: relative;
          min-width: 49px;

          .scheduler-counter {
            position: absolute;
            font-size: 10px;
            color: white;
            background-color: #ff0000;
            border-radius: 50%;
            transform: translate(-60%, -25%);
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }
}

.folders-container.variant-container {
  background-color: #a94442;
}

.page-breadcrumbs {
  display: flex;
  color: #333;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  img {
    width: 21px;
  }

  .main-breadcrumb {
    font-weight: bold;
    margin-left: 5px;
  }
}

.modal-scheduler-container {
  .scheduler-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;

    .scheduler-add {
      background-color: #ffd102;
      padding: 6px 18px;

      img {
        margin-right: 6px;
      }

      .btn-text {
        font-size: 12px;
        text-transform: uppercase;
        color: black;
        vertical-align: middle;
      }

      &:focus,
      &:focus-visible,
      &:active:focus {
        outline: none;
      }
    }
  }

  .scheduler-body {
    max-height: 50vh;
    overflow-y: auto;

    .header {
      background-color: #f5f5f5;
      display: flex;
      font-family: 'Futura PT';
      justify-content: space-between;
      border: 1px solid #e5e5e5;
      border-radius: 3px 3px 0px 0px;
      padding: 8px 0;

      .expended-indicator {
        margin-right: 10px;
        margin-left: 13px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #d9d9d9 no-repeat center;
        background-image: url('../../images/icon-plus-black.svg');
      }

      .indication-wrapper,
      .activity-range-wrapper,
      .buttons-wrapper {
        display: flex;
        align-items: center;
      }

      .indication-wrapper {
        font-size: 16px;
        font-family: 'Futura PT Medium';
        font-weight: 450;
      }

      .activity-range-wrapper {

        .schedular-start-time,
        .schedular-end-time {
          color: #797979;
          font-size: 12px;

          span {
            font-family: 'Futura PT Medium';
            font-weight: 450;
          }
        }

        .schedular-start-time {
          margin-right: 10px;
        }
      }

      .buttons-wrapper {
        position: relative;
        margin-left: 40px;

        .custom-toggle {
          top: 0;
          right: 10px;
          transform: translate(-100%, 50%);
        }
      }

      .btn {
        background: none;
        border: none;

        &:active {
          opacity: 0.7;
        }

        img {
          width: 22px;
          height: 20px;
        }

        &:focus,
        &:focus-visible,
        &:active:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }

    .expended {
      .expended-indicator {
        background-image: url('../../images/icon-minus-black.svg');
      }
    }
  }

  .scheduler-header,
  .scheduler-footer,
  .scheduler-body {
    padding: 19px 21px;
  }

  .scheduler-content {
    border: 1px solid #e5e5e5;
    border-top: none;
    border-radius: 0px 0px 3px 3px;
    padding: 10px 18px 24px 18px;

    li {
      list-style-type: none;
    }

    .react-datepicker__time-container {
      width: 80px;

      .react-datepicker__time {
        .react-datepicker__time-box {
          width: 80px;

          .react-datepicker__time-list-item {
            padding: 5px;
          }
        }
      }
    }

    .schedular-calendars {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .custom-calender {
        label {
          font-size: 16px;
          font-family: 'Futura PT';
          margin-bottom: 0;
        }
      }

      .form-group {
        flex-grow: 1;

        &:first-child {
          margin-right: 15px;
        }
      }

      .date-calender {
        display: block;
        position: absolute;
        top: 0;
        right: 10px;
        width: 20px;
        height: 17px;
        background: url('../../images/calendar-black.svg') no-repeat center;
        transform: translate(0, 50%);
      }
    }

    .scheduler-save {
      display: block;
      margin: 19px auto 0 auto;
      width: 163px;
      height: 35px;
      background: #ffd102;
      border-radius: 3px;
      border: none;
      font-size: 16px;
      font-family: 'Futura PT Medium';

      &:active {
        opacity: 0.7;
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    .scheduled-page-option {
      display: flex;
      align-items: center;

      * {
        margin-right: 4px;
      }
    }
  }

  .scheduler-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;

    .btn {
      width: 173px;
      height: 37px;
      border-radius: unset;
      font-size: 18px;

      &:focus,
      &:focus-visible,
      &:active:focus {
        outline: none;
      }

      &.btn-cancel {
        background-color: white;
        color: black;
        border: 1px solid black;
      }

      &.btn-save {
        background-color: black;
        color: white;
        font-weight: 450;

        &:active {
          opacity: 0.7;
        }
      }
    }
  }
}

.popup-arrow {
  display: none;
}

[role='tooltip'].popup-content {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  padding: 12px;
  font-size: 18px;
  font-family: 'Futura PT';

  .popup-buttons {
    display: flex;
    justify-content: space-between;
  }

  .popup-confirm {
    background-color: #000000;
    border: none;
  }

  .popup-cancel {
    background-color: white;
    border: 2px solid black;
  }

  .popup-confirm,
  .popup-cancel {
    width: 40%;
    height: 32px;
    margin: 12px auto 0px auto;

    &:active {
      opacity: 0.7;
    }
  }
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

/* custom feild info popup */

.info-block {
  background-color: #fbfbfb;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  padding: 0 10px;
  line-height: 15px;
  color: #888;
  width: 100%;

  h6 {
    font-size: 16px;

    &:first-of-type {
      width: 20%;
    }

    &:nth-child(2) {
      width: 30%;
    }
  }
}

.info-details {
  width: 100%;
  padding: 0 10px;

  p:first-of-type {
    width: 20%;
  }

  p:nth-child(2) {
    width: 30%;
  }

  p:last-of-type {
    word-break: break-all;
    width: 50%;
  }
}

.info-content {
  h4 {
    font-weight: 500;
    font-family: 'Futura PT Medium', Arial, SansSerif, serif;
    margin-top: 0;
  }
}

.variant-select {
  width: 50%;
}

.variant-popup-li {
  list-style: none;
  padding: 2px;
  margin: 2px;
}

.variant-popup-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
}