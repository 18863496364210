.cms-headers {
  height: 65px;
}

.width-auto {
  width: auto !important;
}

.btn-container {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.logout-btn {
  width: 100%;
  height: 45px;
  top: 0;
  right: 29px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #797979;
  color: #fff;
  cursor: pointer;
}

.container-spacing {
  padding-left: 5px;
  padding-right: 58px;
}

.header-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #f5f5f5;
}

.header-image {
  padding-bottom: 6px;
  border-right: 1px solid #ddd;
}

.header-nav {
  border-right: 1px solid #ddd;
  padding-top: 13px;
}

.content-bar {
  background-color: #ffffff;
}

.bottom-spacing {
  padding-bottom: 13px;
}

.component-header {
  background-color: #ffffff;
}

.border-none {
  border-bottom: 0;
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0px;
}