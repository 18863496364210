@mixin margin-classes {
  @for $i from 0 through $spacerSizes {
     $margin: $i * $spacer;
    .m-#{$i}  {
      margin: $margin !important;
    }
    .ml-#{$i} {
      margin-left: $margin !important;
    }
    .mr-#{$i} {
      margin-right: $margin !important;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
    .m-n#{$i}  {
      margin: -$margin !important;
    }
    .ml-n#{$i} {
      margin-left: -$margin !important;
    }
    .mr-n#{$i} {
      margin-right: -$margin !important;
    }
    .mt-n#{$i} {
      margin-top: -$margin !important;
    }
    .mb-n#{$i} {
      margin-bottom: -$margin !important;
    }
    .mx-n#{$i} {
      margin-left: -$margin !important;
      margin-right: -$margin !important;
    }
    .my-n#{$i} {
      margin-top: -$margin !important;
      margin-bottom: -$margin !important;
    }
  }
}

@mixin padding-classes {
  @for $i from 0 through $spacerSizes {
    $padding: $i * $spacer;
    .p-#{$i} {
      padding: $padding !important;
    }
    .pl-#{$i} {
      padding-left: $padding !important;
    }
    .pr-#{$i} {
      padding-right: $padding !important;
    }
    .pt-#{$i} {
      padding-top: $padding !important;
    }
    .pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@mixin width-classes {
  @for $i from 0 through $widthSteps {
    $widthStepName: $i * 10;
    $width: $i * $widthStep;
    .width-#{$widthStepName} {
      width: $width !important;
    }
  }
}