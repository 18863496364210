.sub-header {
  padding: 12px 13px;
  margin-bottom: 20px;
  height: 42px;
  background-color: gainsboro;
}
.sub-header-wrapper {
  margin-top: -19px;
}
.home-icon {
  background: url('../../images/icon-home.png');
  background-repeat: no-repeat;
  height: 18px;
  width: 22px;
  display: inline-block;
}
.sub-header li {
  display: inline-block;
  padding-left: 5px;
  vertical-align: top;
}

.sub-header li:first-child {
  padding-left: 15px;
}
.sub-header li:nth-child(2) {
  padding-left: 15px;
}

a {
  text-decoration: none !important;
  color: #000000 !important;
}
