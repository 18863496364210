.page-main-heading {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-left: 0;
  margin-top: 15px;
  margin-bottom: 30px;
  font-family: 'Futura PT Medium';
}

.panel-container {
  min-height: 500px;
  display: flex;
}

.right-container {
  flex: 4;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.content-container {
  height: 100%;
  padding: 20px 30px 0 30px;
}

.side-nav {
  padding: 15px 0 15px 0;
  height: 56px;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  display: block;
  border-top: 1px solid #ffffff33;
  cursor: pointer;
}

.side-nav:hover {
  background: #4a90e2;
}

.custom-textarea {
  margin: 0;
  height: 300px;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  width: 100%;
}

.required {
  color: #f21a1a;
}

.required:after {
  content: '*';
}

.content-colour {
  color: #007aff !important;
}

.add-btn {
  color: #000000 !important;
  font-weight: bold;
}

.flex-right {
  display: flex;
  flex-direction: row-reverse;
}

.float-right {
  float: right;
  cursor: pointer;
}

.m-t-3 {
  margin-top: 3px;
}

.schedulerTitle {
  color: #9b9b9b;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 7px;
}

.dateTitle {
  height: 16px;
  width: 34px;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

/*--------react toggle-------------*/

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  height: 20px;
  width: 37px;
  padding: 0;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1.5px solid #e5e5ea;
  border-radius: 15.5px;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ffffff;
}

.react-toggle--checked .react-toggle-track {
  background-color: #4cd964;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4cd964;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #fafafa;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05),
    0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 19px;
  border-color: #4cd964;
}

.custom-toggle-checked > div > div {
  border: 0;
}

.custom-toggle {
  position: absolute;
  right: 100px;
  top: 33px;
}

@media (min-width: 1200px) {
  body .container {
    width: 1220px;
    margin: 0 auto;
    padding: 0;
  }
}

.pad-left-0 {
  padding-left: 0;
}

.cms-wrapper {
  width: 100%;
}

.wrapper-box {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.wrapper-box .heading-wrapper h3 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Futura PT Medium', Arial, SansSerif, serif;
  font-weight: 500;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 50px;
}

.wrapper-box .heading-wrapper h3 .info {
  display: inline-block;
  margin-left: 10px;
  position: relative;
}

.wrapper-box .info img {
  width: 15px;
  vertical-align: top;
  margin-top: 1px;
}

.wrapper-box .info .tooltiptext {
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50.1%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 25px;
  min-width: 150px;
  width: auto;
  height: auto;
  max-width: 220px;
  background-color: #8b8b8b;
  color: #fff !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  -webkit-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  font-size: 12px !important;
  line-height: 14px;
}

.wrapper-box .info .tooltiptext:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 1;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #8b8b8b transparent transparent;
}

.wrapper-box .info:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.wrapper-box .heading-wrapper p {
  font-size: 14px;
  font-family: 'Futura PT Book', Arial, SansSerif;
  color: #000;
  margin-bottom: 0;
}

.wrapper-box .heading-wrapper {
  background-color: #f5f5f5;
  padding: 10px 15px;
  position: relative;
  min-height: 38px;
  align-items: center;
  cursor: pointer;
}

.toggle-banner {
  margin-right: 10px;

  &:after {
    content: '';
    width: 12px;
    height: 12px;
    padding: 2px 10px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    cursor: pointer;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
  }
}

.minus-banner:after {
  background: #d9d9d9 url('../../images/icon-minus-black.svg') no-repeat 4px;
  background-size: 60% 15%;
}

.plus-banner:after {
  background: #d9d9d9 url('../../images/icon-plus-black.svg') no-repeat 4px;
  background-size: 60% 60%;
}

.drag-pins {
  position: absolute;
  right: 20px;
  top: 10px;
}

.form-group.disable-input {
  position: relative;
}

.noBorder .data-wrapper {
  padding: 10px 0 !important;
}

.wrapper-box .data-wrapper {
  padding: 10px 20px;
}

.wrapper-box .data-wrapper .english-wrapper,
.wrapper-box .data-wrapper .arabic-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.wrapper-box .data-wrapper textarea {
  border-color: #d5d5d5;
  width: 100%;
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
}

.wrapper-box .data-wrapper .english-wrapper .english-content {
  padding-right: 15px;
}

.wrapper-box .data-wrapper h4 {
  font-size: 16px;
  color: #797979;
  font-family: 'Futura PT Medium', Arial, SansSerif;
  font-weight: 500;
}

.wrapper-box .data-wrapper input {
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
}

.wrapper-box .data-wrapper .form-group label {
  font-size: 16px;
  color: #797979;
  font-family: 'Futura PT Book', Arial, SansSerif;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 5px;
  margin-top: 8px;
}

.wrapper-box .data-wrapper .form-group .custom-calender {
  position: relative;
}

.wrapper-box .data-wrapper .form-group .custom-calender .date-calender {
  content: '';
  position: absolute;
  background: url('../../images/icon-calendar.svg') no-repeat;
  width: 26px;
  height: 22px;
  top: 38px;
  right: 14px;
  z-index: 0;
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.preview {
  width: 130px;
  height: 130px;
  background-color: #eee;
  position: relative;
}

.img-preview {
  display: flex;
  position: relative;
}

.left-spacing {
  padding-left: 15px;
}

.camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 21px;
  margin-top: 45px;
  margin-left: 40px;
  position: absolute;
  background-size: 45px 34px;
  cursor: pointer;
}

.marginBottom {
  margin-bottom: 50px !important;
}

.img-title {
  position: absolute;
  top: -26px;
  font-family: 'Futura PT Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #797979;
}

.hover-img-title {
  position: absolute;
  top: -26px;
  font-family: 'Futura PT Book';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #797979;
}

.icon-hover:hover .icon-close {
  background: url('../../images/dustbin.png');
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  padding: 14px;
  top: 5px;
  background-size: 28px 23px;
  margin-left: 101px;
}

.label-height-width {
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  background-size: 28px 23px;
  padding-left: 5px;
  bottom: 0px;
  font-size: 12px;
  background: white;
  width: 100%;
}

.icon-hover:hover .hover-camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 17px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-size: 37px 30px;
  cursor: pointer;
}

.hover-icon:hover .icon-close {
  background: url('../../images/dustbin.png');
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  padding: 14px;
  top: 5px;
  background-size: 28px 23px;
  margin-left: 101px;
}

.hover-icon:hover .img-overlay {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hover-icon:hover .hover-camera-icon {
  background: url('../../images/camera-icon.png');
  background-repeat: no-repeat;
  padding: 17px;
  top: 50px;
  left: 50px;
  position: absolute;
  background-size: 37px 30px;
  cursor: pointer;
}

.opacity {
  opacity: 0.5;
}

.icon-hover:hover .img-overlay {
  background-color: white;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
}

.img-info {
  position: absolute;
  top: -23px;
  left: 62px;
}

.hover-img-info {
  position: absolute;
  top: -23px;
  left: 254px;
}

.delete-btn {
  background: url('../../images/trashbin.svg') no-repeat;
  left: auto;
  right: 73px;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
}

.copy-content {
  background: url('../../images/copy-content.png') no-repeat;
  left: auto;
  right: 145px;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
}

.dynamic-module-heading {
  .clone-btn {
    right: 150px;
  }
}

.banner-heading {
  .clone-btn {
    right: 205px;
  }
}

.nav-link {
  .clone-btn {
    right: 10px;
  }
}

.clone-btn {
  background: url('../../images/copy-content.png') no-repeat;
  left: auto;
  right: 180px;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
}

.banner-clone-btn {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEcSURBVHgBtZVtEYMwDIbT3QSAAsABKFgdMAcgASfMweYECcxBcQAOsqSwDRgtH4PnLvRH2rchl6QAFhBRkuVkiqzGLyXZnf2wlk5U4TJ4X7JU+IbbyOeES/yPcu+Ix+Rj4RT3RbKu6MQVLb4pXVVVQZqmeh3jui4kSQJZlg2OCCGCd2VYKYoCOQaTSTkpIc/kNJZR0zTaNhKf6BOavFEUQRAEsJGrVXwqxytwTnAcWnxzUuc4UvwpsO2obMpLtapXrmHP88BxnIHf9329hmH44yMe1joHS22zKaVs7XHRV6BhvMZxbBSmaG3C6hM/LujSlQwbE9sXZw+m5zoeNc93+AP7S9S7gOe7WijK+y6wFj6E7SvfT1fdCeZzoi/L1sHTmc3pGwAAAABJRU5ErkJggg==)
    no-repeat;
  left: auto;
  right: 180px;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 150px;
}

.clone-btn-country {
  background: url('../../images/clone-country.png') no-repeat;
  left: auto;
  right: 217px;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 10px;
}

.catalog-image-list .custom-toggle {
  right: 105px;
  left: auto;
}

.radio_group p .custom_radio {
  position: absolute;
  width: 20px;
  height: 17px;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.radio_group p {
  position: relative;
}

.custom_radio + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000000;
}

.custom_radio + label:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 15px;
  height: 15px;
  border: 1px solid #f8c500;
  border-radius: 100%;
  background: #fff;
}

.custom_radio + label:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
}

.checked_radio + label:before {
  border: 1px solid #f8c500;
  border-radius: 100%;
  background-image: linear-gradient(
    90deg,
    rgb(248, 158, 0) 0%,
    rgb(248, 208, 0) 100%
  );
  background-image: -moz-linear-gradient(
    90deg,
    rgb(248, 158, 0) 0%,
    rgb(248, 208, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(248, 158, 0) 0%,
    rgb(248, 208, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(248, 158, 0) 0%,
    rgb(248, 208, 0) 100%
  );
}

.checked_radio + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  background: #fff;
}

.radio_group {
  margin-bottom: 35px;
}

.radio_group p {
  margin-bottom: 0;
  margin-right: 25px;
}

.add_new {
  border: 1px dashed #6e6e6e !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
}

.add_new .radio_group {
  margin: 0;
  padding: 8px 0 2px 20px;
  width: 85%;
}

.add_new .custom-btn {
  margin: 0;
  padding: 0;
  line-height: 30px;
  border-left: 1px dashed #6e6e6e !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.page_information {
  display: flex;

  .authorization-checkbox {
    .form-group {
      display: flex;
      margin-bottom: 0;
    }
  }
}

.page_information input {
  width: 33.3%;
  margin-right: 10px;
  border-radius: 0;
  padding: 18px 20px;
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
}

.page_information select {
  margin-right: 0;
}

.top_custom_dropdown {
  height: 38px;
  cursor: pointer;
  color: #555;
  border-radius: 0;
  padding: 8px 20px;
  font-size: 14px;
  border: 1px solid #ccc !important;
  width: 33.3%;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.top_custom_dropdown .dropbtn {
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
  color: #797979;
}

.top_custom_dropdown .dropdown-content {
  top: 37px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
  color: #797979;
}

.top_custom_dropdown:after {
  content: '';
  position: absolute;
  background: url('../../images/arrow-dd.svg') no-repeat;
  top: 18px;
  right: 10px;
  width: 10px;
  height: 10px;
  transition: all 0.4s;
}

.top_custom_dropdown:hover:after {
  top: 13px;
  transform: rotate(180deg);
  transition: all 0.4s;
}

.mrgnTp30 {
  margin-top: 30px !important;
}

.scheduler_accordion {
  position: relative;
}

.scheduler_accordion .toggle-banner {
  position: absolute;
  right: 1px;
  z-index: 9;
}

.catalog-image-list .preview {
  margin-top: 35px;
}

.slider-image-list .preview {
  margin-top: 35px;
}

.disable-pointer-events {
  pointer-events: none;
  background-color: rgba(204, 204, 204, 0.5);
}

.react-toggle {
  top: -24px;
}

.react-toggle.custom-toggle {
  top: 10px;
}

.page-delete-btn {
  cursor: pointer;
  position: absolute;
  top: -63px;
  right: 20px;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  left: auto;
  background: url('../../images/delete.png');
  &.disabled-img{
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.catalog-url {
  position: relative;
  width: 33.3%;
  margin-right: 10px;
}

.catalog-url input {
  position: absolute;
  width: 100%;
  border-radius: 0;
  padding: 18px 20px;
  font-size: 16px;
  font-family: 'Futura PT Book', Arial, SansSerif;
}

.catalog-url .invalid-url-message {
  bottom: -18px;
}

.hover-img-info.mobile-app-img {
  left: 290px;
}

.catalog-header {
  display: flex;
  justify-content: space-between;
}

/* Rollback */
.rollback-block {
  background-color: #fbfbfb;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  padding: 0 10px;
  line-height: 15px;
  color: #888;
  width: 100%;

  h6 {
    font-size: 16px;
    &:first-of-type {
      width: 45%;
    }
    &:nth-child(2) {
      width: 45%;
    }
    &:nth-child(3) {
      width: 13%;
    }
  }
}

.rollback-list {
  padding: 5px 10px;
  width: 100%;
  margin-right: 15px;
  p:first-of-type {
    width: 55%;
  }
  p:nth-child(2) {
    width: 50%;
  }
  p:nth-child(3) {
    width: 17%;
    margin-bottom: 0;
  }
}

.update-module {
  filter: 'brightness(0) invert(1)';
}

.config-toggle {
  position: 'absolute !important';
  top: 10px;
}
