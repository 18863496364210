@font-face {
  font-family: 'Futura PT Book';
  src: url('../font/FuturaPT-Book.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Book'),
    url('../font/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Book.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Book.woff') format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Book.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Book.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT Medium';
  src: url('../font/FuturaPT-Medium.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Medium'),
    url('../font/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Medium.woff')
      format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Medium.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT Bold';
  src: url('../font/FuturaPT-Bold.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Bold'),
    url('../font/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Bold.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('../font/FuturaPT-Demi.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Demi'),
    url('../font/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Demi.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Demi.woff') format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Demi.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Demi.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT';
  src: url('../font/FuturaPT-Light.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Light'),
    url('../font/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Light.woff') format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Light.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT Light';
  src: url('../font/FuturaPT-Light.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Light'),
    url('../font/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPT-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPT-Light.woff') format('woff'),
    /* Modern Browsers */ url('../font/FuturaPT-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPT-Light.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('../font/FuturaPTCond-Book.eot'); /* IE9 Compat Modes */
  src: local('Futura PT Cond Book'),
    url('../font/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/FuturaPTCond-Book.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/FuturaPTCond-Book.woff')
      format('woff'),
    /* Modern Browsers */ url('../font/FuturaPTCond-Book.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../font/FuturaPTCond-Book.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}