
.radio-group-wrapper {
  border: 1px dashed #6e6e6e !important;
  display: flex;
  align-items: center;
  height: 38px;

  .radio-group {
    margin: 0;
    padding-left: 20px;
    min-width: 85%;

    span {
      position: relative;
      margin-bottom: 0;
      margin-right: 25px;
  
      .custom-radio {
        position: absolute;
        width: 20px;
        height: 17px;
        opacity: 0;
        z-index: 10;
        cursor: pointer;

        & + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          margin: 0;

          &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 15px;
            height: 15px;
            border: 1px solid #f8c500;
            border-radius: 100%;
            background: #fff;
          }

          &:after {
            content: '';
            width: 7px;
            height: 7px;
            position: absolute;
            top: 6px;
            left: 6px;
            border-radius: 100%;
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;
            opacity: 0;
          }
        }
      }

      .checked-radio {
        & + label {
          &:before {
            border: 1px solid #f8c500;
            border-radius: 100%;
            background-image: linear-gradient(
              90deg,
              rgb(248, 158, 0) 0%,
              rgb(248, 208, 0) 100%
            );
            background-image: -moz-linear-gradient(
              90deg,
              rgb(248, 158, 0) 0%,
              rgb(248, 208, 0) 100%
            );
            background-image: -webkit-linear-gradient(
              90deg,
              rgb(248, 158, 0) 0%,
              rgb(248, 208, 0) 100%
            );
            background-image: -ms-linear-gradient(
              90deg,
              rgb(248, 158, 0) 0%,
              rgb(248, 208, 0) 100%
            );
          }

          &:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            background: #fff;
          }
        }
      }
    }
  }

  .custom-btn {
    margin: 0;
    padding: 0;
    line-height: 30px;
    border-left: 1px dashed #6e6e6e !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
}

