.url-generation-container {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1%;
}

.url-generation-form-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  color: #ba7101;
}

.url-width-50 {
  width: 50%;
}

.url-left-pd {
  padding-left: 15px;
}

.url-right-pd {
  padding-right: 15px;
}

.url-generation-form {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.url-generation-form-ele {
  display: block;
}

.url-generation-form-span {
  display: block;
}

.url-generation_heading {
  font-family: 'Futura PT Medium';
  font-size: 33px;
  text-align: center;
  color: #000000;
  padding-bottom: 33px;
  padding-top: 33px;
  margin: 0;
}

.sub-heading {
  font-family: 'Futura PT Demi';
  font-size: 18px;
  color: #000000;
  margin: 0;
  padding-bottom: 18px;
}

.url-creation-content {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.url-container {
  position: relative;
  width: 100%;
  margin-right: 18px;
}

.url-input {
  width: 100%;
  height: 35px;
  border: solid 1px #d5d5d5;
  padding: 10px 18px 11px 18px;
  color: #000;
}

.validate-btn {
  width: 129px;
  height: 40px;
  background-color: #000;
  color: #fff;
  margin-left: 35px;
  margin-top: 21px;
  display: block;
}

.validate-btn:hover {
  color: #ffd100;
}

.save-btn {
  width: 129px;
  height: 42px;
  background-color: #000;
  color: #fff;
  margin-right: 15px;
}

.save-btn {
  &:not([disabled]) {
    &:hover {
      color: #ffd100;
    }
  }

  &[disabled] {
    background-color: #808080;
  }
}

.reset-btn {
  width: 129px;
  height: 42px;
  background-color: #000;
  color: #fff;
}

.reset-btn:hover {
  color: #ffd100;
}

.filter-condition-icon {
  background: url('../../images/internet.png');
  background-repeat: no-repeat;
  padding: 12px;
  cursor: pointer;
  display: block;
}

.filter-condition-disable-icon {
  background: url('../../images/internet_disable.png');
  background-repeat: no-repeat;
  padding: 12px;
  cursor: not-allowed;
  display: block;
}

.url-list-section {
  display: flex;
  margin-top: 49px;
  margin-bottom: 14px;
}

.section-heading {
  font-family: 'Futura PT Demi';
  font-size: 18px;
  color: #000;
  margin-top: 10px;
}

.url-list-table {
  position: relative;
}

.url-list-table .table>thead>tr>th {
  padding: 9px 8px 9px 8px !important;
  text-align: start;
  background-color: #e9e9e9;
  color: #797979;
  max-width: 200px;
}

.url-list-table .table>tbody>tr>th {
  padding: 16px 22px 16px 25px !important;
}

.url-list-table .table>tbody>tr>td {
  padding: 6px 5px 6px 8px !important;
  vertical-align: initial;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.url-list-table .table>tbody>tr>td:first-child {
  width: 15%;
  color: #186abc;
}

.url-list-table .table>tbody>tr>td:nth-child(3) {
  width: 15%;
  word-break: break-word;
}

.url-list-table .table>tbody>tr>td:nth-child(5) {
  width: 12%;
  word-break: break-word;
}

.url-list-table .table>tbody>tr>td:nth-child(6) {
  width: 15%;
  word-break: break-word;
}

.url-list-table .table>tbody>tr>td:nth-child(7) {
  width: 12%;
  word-break: break-word;
}

.url-list-table .table>tbody>tr>td:last-child {
  width: 12%;
}

.url-list-table .table>thead>tr>th:first-child {
  width: 15%;
}

.url-list-table .table>thead>tr>th:nth-child(3) {
  width: 15%;
}

.url-list-table .table>thead>tr>th:nth-child(5) {
  width: 8%;
}

.url-list-table .table>thead>tr>th:nth-child(6) {
  width: 15%;
}

.url-list-table .table>thead>tr>th:nth-child(7) {
  width: 10%;
}

.url-list-table .table>thead>tr>th:last-child {
  width: 9%;
}

.action-toggle .react-toggle {
  top: 4px;
  right: 1px;
}

.action-toggle .react-toggle-track {
  height: 18px;
  width: 35px;
}

.action-toggle .react-toggle-thumb {
  height: 14px;
  width: 13px;
}

.delete-icon {
  background: url('../../images/icon-delete.png');
  background-repeat: no-repeat;
  padding: 12px;
  position: absolute;
  background-size: 17px 21px;
}

.edit-icon {
  background: url('../../images/edit-icon.png');
  background-repeat: no-repeat;
  padding: 12px;
  padding-top: 0;
  margin-left: 10px;
  margin-right: 8px;
  background-size: 18px 18px;
}

.url-list-table .table {
  font-size: 15px;
  font-family: 'Futura PT';
  color: #222;
}

.page-type-dropdown {
  width: 18%;
  height: 42px;
  padding-right: 10px;
}

.disable-button {
  background-color: #808080;
  color: #fff;
}

.product-count-message {
  color: #06ac65;
  font-family: 'Futura PT';
  font-size: 16px;
  position: absolute;
  bottom: -54px;
  width: 100%;
  left: 5px;
}

.invalid-url-message {
  color: #c13a33;
  font-family: 'Futura PT';
  font-size: 16px;
  position: absolute;
  bottom: -34px;
  width: 100%;
  left: 5px;
}

.invalid-count-message {
  color: #c13a33;
  font-family: 'Futura PT';
  font-size: 16px;
  position: absolute;
  width: 100%;
  left: 5px;
}

.filter-container {
  position: relative;
  width: 100%;
}

.invalid-filter-message {
  color: #c13a33;
  font-family: 'Futura PT';
  font-size: 16px;
  position: absolute;
  bottom: -54px;
  left: 5px;
}

.filter-input {
  width: 100%;
  height: 42px;
  border: solid 1px #d5d5d5;
  padding: 10px 18px 11px 18px;
}

.invalid-page-type {
  color: #c13a33;
  font-family: 'Futura PT';
  font-size: 16px;
  bottom: -34px;
  left: 34px;
}

.page-type-title-descp {
  margin-top: 50px;
}

.page-type-title-descp input {
  margin-right: 10px;
  margin-bottom: 20px;
}

.seo-title-descp {
  width: 100%;
}

.apply-for-search {
  background-color: #ffd100;
  padding: 10px 20px;
  color: #8e7400;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
}

.search-icon-btn-url {
  height: 38px;
  right: 15px;
  padding-left: 10px;
  background-color: #000;
  border: 1px solid darkgray;
}

.pagination-url {
  display: flex;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
}

.pagination-url>.active>a,
.pagination-url>.active>a:hover,
.pagination-url>.active>a:focus {
  cursor: default;
  background-color: #f8d000 !important;
  border-color: transparent !important;
}

.pagination-url>li>a {
  padding: .375rem .75rem;
  color: #222 !important;
  background-color: #fff;
  border: 1px solid #ddd;
  position: relative;
  text-decoration: none;
  display: block;
}

.pagination-url>li:not(:first-child)>a {
  margin-left: -1px;
}

.pagination-url>li:first-child>a,
.pagination-url>li:last-child>a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-url>li>a:hover,
.pagination-url>li>a:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination-url>.active>a,
.pagination-url>.active>a:hover,
.pagination-url>.active>a:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.pagination-url>.disabled>a,
.pagination-url>.disabled>a:hover,
.pagination-url>.disabled>a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.url-search-input {
  width: 87%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.url-search-input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.url-creation-table {
  min-width: 100%;
  margin-bottom: 0px !important;
}

.url-toggle {
  top: 0px;
}