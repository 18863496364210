.mui-hover-icon {
  .mui-camera-icon {
    position: absolute;
    margin: 48px;
    cursor: pointer;
    display: none;
  }

  .mui-video-icon {
    position: absolute;
    margin: 48px;
    cursor: pointer;
    display: none;
  }

  .mui-icon-close {
    position: absolute;
    margin-left: 105px;
    cursor: pointer;
    display: none;
  }

  .mui-icon-copy {
    position: absolute;
    cursor: pointer;
    display: none;
  }

  .mui-icon-play {
    position: absolute;
    margin-left: 50px;
    cursor: pointer;
    display: none;
  }

  .mui-icon-play-center {
    cursor: pointer;
    margin-top: 50px;
    margin-left: 50px;
  }

  .mui-img-overlay {
    background-color: white;
    opacity: 0.7;
    position: absolute;
    width: 128px;
    height: 128px;
    display: none;
  }

  &:hover {
    .mui-camera-icon {
      display: inline-block;
    }

    .mui-video-icon {
      display: inline-block;
    }

    .mui-icon-close {
      display: inline-block;
    }

    .mui-icon-copy {
      display: inline-block;

    }

    .mui-img-overlay {
      display: inline-block;
    }

    .mui-icon-play {
      display: inline-block;
    }

    .mui-icon-play-center {
      opacity: 0.2;
    }
  }
}