.apply-for-wrapper {
  display: flex;

  .labelTxt {
    white-space: nowrap;
  }
  justify-content: flex-end;

  .drpdwn_wrapper {
    width: 32%;
    background: white;
  }
}
.labelTxt > p {
  margin-bottom: 0;
  margin-top: 5px;
}
.drpdwn_wrapper .apply-for-dropdown.withCheckbox {
  border: 1px solid#dfdfdf;
  border-radius: 3px;
  padding: 5px 10px;
}
.apply-for-dropdown.withCheckbox > .dropdown {
  margin-top: 0;
}
.apply-for-dropdown.withCheckbox .dropdown-content {
  font-size: 16px;
}
.apply-for-dropdown.withCheckbox .dropdown-content span {
  display: flex;
  padding: 9px 10px;
}
.apply-for-dropdown.withCheckbox label {
  width: 18px;
  height: 18px;
  margin-bottom: 0;
}
.apply-for-dropdown.withCheckbox label input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.apply-for-dropdown.withCheckbox label.checkedBox:after {
  font-size: 14px;
  left: 2px;
  top: 1px;
}
.apply-for-dropdown.withCheckbox span.disabled {
  opacity: 0.7;
  cursor: default;
}
.apply-for-dropdown.withCheckbox span.disabled label,
.apply-for-dropdown.withCheckbox span.disabled .lblTxt,
.apply-for-dropdown.withCheckbox span.disabled input[type='checkbox'] {
  cursor: default;
}

.apply-for-dropdown.withCheckbox .uncheckedBox {
  border: 2px solid transparent;
}
.apply-for-dropdown.withCheckbox .uncheckedBox:hover {
  border-color: #fdd130;
}
.apply-for-dropdown.withCheckbox .lblTxt {
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 0;
}
