.custom-btn {
  height: 38px;
  width: auto;
  min-width: 160px;
  padding: 8px 12px 8px 12px;
  background: #000000;
  color: white;
  margin: 25px 15px 10px 0;
  font-family: 'Futura PT Book';
  font-size: 14px;
}

.filled-sm-btn {
  height: 25px;
  width: auto;
  min-width: 100px;
  background: #000000;
  color: white;
  font-family: 'Futura PT Book';
  font-size: 12px;
  &:hover {
    background: #fff;
    color: #000;
  }
}
