@include margin-classes;
@include padding-classes;
@include width-classes;

.d-flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 100% !important;
}
