.banner-helping-html {
  margin-top: -15px;
  code {
    color: #337ab7;
  }

  &.wrapper-box {
    & > .heading-wrapper {
      padding: 5px 20px;
      min-height: unset;

      & > h3 {
        font-family: 'Futura PT Book';
        font-size: 14px;
      }
    }

    & > .data-wrapper {
      h4 {
        padding-bottom: 10px;
      }

      ul {
        padding-left: 25px;
      }
    }
  }
}
